import { useEffect, useState } from 'react';
import Scroll from './SmoothScroll';

function App() {
  const [images, setImages] = useState();
  const [loaded, setLoaded] = useState(false);
  const [minLoadTime, setMinLoadTime] = useState(false);

  setTimeout(() => {
    setMinLoadTime(true)
  }, 1000);


  useEffect(() => {
    fetch('https://picsum.photos/v2/list?limit=5').then(res => res.json())
      .then(json => {
        //console.log(json);
        setImages(json);
        setLoaded(true)
      });

  }, [])

  return (
    <>
      {
        !(minLoadTime && loaded) ?
          <Loading />
          :
          <div className="App">
            <h1 className="title">React Smooth Scroll</h1>
            <Scroll />

            <div className="text">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis unde voluptas reiciendis obcaecati sapiente eius necessitatibus. Commodi molestias ab sint magnam libero, eveniet culpa quo! Perspiciatis, numquam voluptas. Culpa, beatae!
              </p>
              <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet ut tenetur quaerat voluptate, exercitationem porro. Quo distinctio assumenda ipsam laboriosam cumque maiores sequi deleniti esse labore veniam. Est labore voluptas vero eius obcaecati atque quas nam ut ipsam repellendus! Possimus accusantium excepturi illo quos quae vitae nulla exercitationem ex velit fugit quis saepe ab incidunt nobis, libero similique tenetur eius? Iste officiis voluptate ea et iure perferendis adipisci, sequi, similique quidem voluptates a temporibus enim blanditiis? Porro, animi sequi eveniet dignissimos aliquam nihil assumenda dolorum consequuntur itaque atque laboriosam accusantium corporis ad voluptatem delectus sunt ex, alias veniam modi asperiores.</h1>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis unde voluptas reiciendis obcaecati sapiente eius necessitatibus. Commodi molestias ab sint magnam libero, eveniet culpa quo! Perspiciatis, numquam voluptas. Culpa, beatae!
              </p>
              <h2>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet ut tenetur quaerat voluptate, exercitationem porro. Quo distinctio assumenda ipsam laboriosam cumque maiores sequi deleniti esse labore veniam. Est labore voluptas vero eius obcaecati atque quas nam ut ipsam repellendus! Possimus accusantium excepturi illo quos quae vitae nulla exercitationem ex velit fugit quis saepe ab incidunt nobis, libero similique tenetur eius? Iste officiis voluptate ea et iure perferendis adipisci, sequi, similique quidem voluptates a temporibus enim blanditiis? Porro, animi sequi eveniet dignissimos aliquam nihil assumenda dolorum consequuntur itaque atque laboriosam accusantium corporis ad voluptatem delectus sunt ex, alias veniam modi asperiores.</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis unde voluptas reiciendis obcaecati sapiente eius necessitatibus. Commodi molestias ab sint magnam libero, eveniet culpa quo! Perspiciatis, numquam voluptas. Culpa, beatae!
              </p>
              <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet ut tenetur quaerat voluptate, exercitationem porro. Quo distinctio assumenda ipsam laboriosam cumque maiores sequi deleniti esse labore veniam. Est labore voluptas vero eius obcaecati atque quas nam ut ipsam repellendus! Possimus accusantium excepturi illo quos quae vitae nulla exercitationem ex velit fugit quis saepe ab incidunt nobis, libero similique tenetur eius? Iste officiis voluptate ea et iure perferendis adipisci, sequi, similique quidem voluptates a temporibus enim blanditiis? Porro, animi sequi eveniet dignissimos aliquam nihil assumenda dolorum consequuntur itaque atque laboriosam accusantium corporis ad voluptatem delectus sunt ex, alias veniam modi asperiores.</h3>
              {
                images && images.map(
                  img => <div key={img.id} className="imgContainer">
                    <img src={img.download_url} alt={img.author} />
                  </div>
                )
              }
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis unde voluptas reiciendis obcaecati sapiente eius necessitatibus. Commodi molestias ab sint magnam libero, eveniet culpa quo! Perspiciatis, numquam voluptas. Culpa, beatae!
              </p>
              <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet ut tenetur quaerat voluptate, exercitationem porro. Quo distinctio assumenda ipsam laboriosam cumque maiores sequi deleniti esse labore veniam. Est labore voluptas vero eius obcaecati atque quas nam ut ipsam repellendus! Possimus accusantium excepturi illo quos quae vitae nulla exercitationem ex velit fugit quis saepe ab incidunt nobis, libero similique tenetur eius? Iste officiis voluptate ea et iure perferendis adipisci, sequi, similique quidem voluptates a temporibus enim blanditiis? Porro, animi sequi eveniet dignissimos aliquam nihil assumenda dolorum consequuntur itaque atque laboriosam accusantium corporis ad voluptatem delectus sunt ex, alias veniam modi asperiores.</h1>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis unde voluptas reiciendis obcaecati sapiente eius necessitatibus. Commodi molestias ab sint magnam libero, eveniet culpa quo! Perspiciatis, numquam voluptas. Culpa, beatae!
              </p>
              <h2>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet ut tenetur quaerat voluptate, exercitationem porro. Quo distinctio assumenda ipsam laboriosam cumque maiores sequi deleniti esse labore veniam. Est labore voluptas vero eius obcaecati atque quas nam ut ipsam repellendus! Possimus accusantium excepturi illo quos quae vitae nulla exercitationem ex velit fugit quis saepe ab incidunt nobis, libero similique tenetur eius? Iste officiis voluptate ea et iure perferendis adipisci, sequi, similique quidem voluptates a temporibus enim blanditiis? Porro, animi sequi eveniet dignissimos aliquam nihil assumenda dolorum consequuntur itaque atque laboriosam accusantium corporis ad voluptatem delectus sunt ex, alias veniam modi asperiores.</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis unde voluptas reiciendis obcaecati sapiente eius necessitatibus. Commodi molestias ab sint magnam libero, eveniet culpa quo! Perspiciatis, numquam voluptas. Culpa, beatae!
              </p>
              <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet ut tenetur quaerat voluptate, exercitationem porro. Quo distinctio assumenda ipsam laboriosam cumque maiores sequi deleniti esse labore veniam. Est labore voluptas vero eius obcaecati atque quas nam ut ipsam repellendus! Possimus accusantium excepturi illo quos quae vitae nulla exercitationem ex velit fugit quis saepe ab incidunt nobis, libero similique tenetur eius? Iste officiis voluptate ea et iure perferendis adipisci, sequi, similique quidem voluptates a temporibus enim blanditiis? Porro, animi sequi eveniet dignissimos aliquam nihil assumenda dolorum consequuntur itaque atque laboriosam accusantium corporis ad voluptatem delectus sunt ex, alias veniam modi asperiores.</h3>
            </div>
          </div>
      }
    </>
  );
}

export default App;


const Loading = () => {
  return (
    <div className="loading">
      <h3>
        loading...
      </h3>
    </div>
  )
}